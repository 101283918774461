import { createStore, combineReducers } from "redux";
import reducers from "../reducers";
import AppState from "../store/reducers/app_reducer";

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers,AppState
    }),
    {}
  );
}
