import {BASE_URL} from './service_const'
import {showErrorMessage,get_value_from_object} from '../ui-components/ui_functions'
import AuthenticationService from './AuthenticationService'
class AjaxService{
    
    HTTP_CODES_MESSAGE = {
        404:'Invalid request . Seems there is a problem with request. Please check request url or method',
        405:'Request method is not supported . Please contact support',
        500:'Invalid request . Seems there is a problem with request. Please check request url or method'
    };

   async makeGetRequest(url){
        const headerParams = await this._buildHeader();
        const request = {
            method:'GET',
            headers:headerParams
        };
        let http_url = this._buildURL(url)
        if (url.startsWith("http:")||url.startsWith("https:")){
            http_url = url
        }
        let response =  await fetch(http_url,request)
        .then(this._parseResponse.bind(this))
        .then(this._parseServerJsonResponse.bind(this))
        .catch(this._handleNetworkCode.bind(this));
        return response;
    }

    async makePostRequest(url,body){
        const headerParams = await this._buildHeader();
        const request = {
            method:'POST',
            headers:headerParams
        };
        if(body !=null ){
            request['body'] = JSON.stringify(body);
        }
        let response =  await fetch(this._buildURL(url),request)
        .then(this._parseResponse.bind(this))
        .then(this._parseServerJsonResponse.bind(this))
        .catch(this._handleNetworkCode.bind(this))
        return response;
    }

    async makePutRequest(url,body){
        const headerParams = await this._buildHeader();
        const request = {
            method:'PUT',
            headers:headerParams
        };
        if(body !=null ){
            request['body'] = JSON.stringify(body);
        }
        let response =  await fetch(this._buildURL(url),request)
        .then(this._parseResponse.bind(this))
        .then(this._parseServerJsonResponse.bind(this))
        .catch(this._handleNetworkCode.bind(this))
        return response;
    }


    async makePostWithFullURLRequest(url,body){
        const headerParams = await this._buildHeader();
        const request = {
            method:'POST',
            headers:headerParams
        };
        if(body !=null ){
            request['body'] = JSON.stringify(body);
        }
        let response =  await fetch(url,request)
        .then(this._parseResponse.bind(this))
        .then(this._parseServerJsonResponse.bind(this))
        .catch(this._handleNetworkCode.bind(this))
        return response;
    }

    


    async _buildHeader(){
       
       return {
        'Content-Type': 'application/json',
         'accept' : 'application/json',
         'cache-control': "no-cache",
         'pragma':'no-cache',
         'X-AUTH-TOKEN':AuthenticationService.getToken()
       };
    }
    /**
     * @param {*} response 
     */
    _parseResponse(response){
       if(!response.ok){
        showErrorMessage(this.HTTP_CODES_MESSAGE[response.status] || 'System facing some technical issue');
        return {errorMessage:this.HTTP_CODES_MESSAGE[response.status] || 'System facing some technical issue',status:"Error"}
       }else{
        var contentType = response.headers.get('content-type');
        if(contentType && contentType.includes('application/json')) {
            try{
                return response.json();
            }catch(e){
                return {status:"Error",success:false,errorMessage:'Response content type is not understandable. Please contact administrator'};
            }
        }else {
            return {status:"Error",success:false,errorMessage:'Response content type is not understandable. Please contact administrator'};
        }
       }
    }
    /**
     * 
     * @param {*} data 
     */
    _parseServerJsonResponse(data){
       const is_logout =  get_value_from_object(data || {},"status","") === "NOT_LOGGED_IN"
       if(is_logout){
           AuthenticationService.goToLogin()
       }
        return data || {};
    }
    /**
     * 
     * @param {*} error 
     * @param {*} data 
     */
    _handleNetworkCode(error){
        let errorMessage ="Not found"
        if (error.message === 'Failed to fetch'){
            errorMessage ="OOPs! unable to connect server. Please check internet connectivity"
            showErrorMessage(errorMessage);
        }
        return {status:"Error",success:false,errorMessage:errorMessage};
    }

    _buildURL(url){
        return BASE_URL+url;
    }
}

export default new AjaxService();