import { Route, Redirect } from "react-router-dom";
import React, { Suspense, lazy, Fragment } from "react";
import Loader from "react-loaders";

import { ToastContainer } from "react-toastify";
import AjaxLoader from '../../ui-components/loader'

const UserPages = lazy(() => import("../../DemoPages/UserPages"));
const Applications = lazy(() => import("../../DemoPages/Applications"));
const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));

const Widgets = lazy(() => import("../../DemoPages/Widgets"));
const Elements = lazy(() => import("../../DemoPages/Elements"));
const Components = lazy(() => import("../../DemoPages/Components"));
const Charts = lazy(() => import("../../DemoPages/Charts"));
const Forms = lazy(() => import("../../DemoPages/Forms"));
const Tables = lazy(() => import("../../DemoPages/Tables"));
const Pulse = lazy(() => import("../../pages/landing_page"));

// www
const Gis = lazy(() => import('../../www/Gis'));

const AppMain = () => {

    return (
        <Fragment>
            <ToastContainer />
            {/* Components */}

            <Suspense fallback={<AjaxLoader />}>
                <Route path="/components" component={Components}/>
            </Suspense>

            {/* Forms */}

            <Suspense fallback={<AjaxLoader />}>
                <Route path="/forms" component={Forms}/>
            </Suspense>

            <Suspense fallback={<AjaxLoader />}>
                <Route path="/pulse" component={Pulse}/>
            </Suspense>

            {/* Charts */}

            <Suspense fallback={<AjaxLoader />}>
                <Route path="/charts" component={Charts}/>
            </Suspense>

            {/* Tables */}

            <Suspense fallback={<AjaxLoader />}>
                <Route path="/tables" component={Tables}/>
            </Suspense>

            {/* Elements */}

            <Suspense fallback={<AjaxLoader />}>
                <Route path="/elements" component={Elements}/>
            </Suspense>

            {/* Dashboard Widgets */}

            <Suspense fallback={<AjaxLoader />}>
                <Route path="/widgets" component={Widgets}/>
            </Suspense>

            {/* Pages */}

            <Suspense fallback={<AjaxLoader />}>
                <Route path="/pages" component={UserPages}/>
            </Suspense>

            {/* Applications */}

            <Suspense fallback={<AjaxLoader />}>
                <Route path="/apps" component={Applications}/>
            </Suspense>

            {/* Dashboards */}

            <Suspense fallback={<AjaxLoader />}>
                <Route path="/dashboards" component={Dashboards}/>
            </Suspense>

            {/* GIS */}

            <Suspense fallback={<AjaxLoader />}>
                <Route path="/gis" component={Gis}/>
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/pulse/dashboard"/>
            )}/>
           
        </Fragment>
    )
};

export default AppMain;
