import { toast } from 'react-toastify'
import React from 'react'
import { Progress, Row, Col, Container } from 'reactstrap'
import Chart from 'react-apexcharts'

export const showSuccessMessage = (message) => toast.success(message);
export const showErrorMessage = (message) => toast.error(message);

export const format_iso_date = (value) => {
    if (value && value instanceof Date) {
        let month = '' + (value.getMonth() + 1);
        let day = '' + value.getDate();
        let year = value.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    return value
};

export const parse_iso_date = (value) => {

    if (value && value instanceof Date) {
        return value
    }
    if (value && typeof value === "string") {

        const in_dates_part = value.split("-");
        if (in_dates_part.length > 2) {
            const parsed_date = new Date(parseInt(in_dates_part[0]), parseInt(in_dates_part[1]) - 1, parseInt(in_dates_part[2]));
            return parsed_date
        }
    }
    return value
};

export const get_value_from_object = function (in_value, key, default_value) {
    const safe_res = in_value || {}
    return safe_res[key] || default_value
};
export const cell_text_renderer = function (value) {
    return (
        <div>
            <div className="widget-content p-0 text-center">
                <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2 text-center">
                        {value}
                    </div>
                </div>
            </div>
        </div>
    )
};
export const cell_link_renderer = function (value) {
    return (
        <div>
            <div className="widget-content p-0 text-center">
                <div className="widget-content-wrapper">
                    <div className="widget-content-left flex2 text-center">
                        {value}
                    </div>
                </div>
            </div>
        </div>
    )
};

export const cell_utilization_render = function (values) {
    const compute_color = (value) => {
        let color = "success"
        if (value > 80) color = "danger";
        else if (value > 60 && value < 80) color = "warning";
        return color;
    }

    return (
        <Container>

            <Progress multi max={100}>
                <Progress bar color={compute_color(values[0])} value="33" max={100}>Dec</Progress>
                <Progress bar color={compute_color(values[1])} value="33" max={100} >Jan</Progress>
                <Progress bar color={compute_color(values[2])} value="33" max={100}>Feb</Progress>
            </Progress>
        </Container>

    )
}


export const cell_rating_render = function (values, key, valueKey) {
    const safe_rating = values || []
    const compute_color = (value) => {
        let color = "danger"
        if (value > 80) color = "success";
        else if (value > 60 && value < 80) color = "warning";
        return color;
    }
    const size = 100 / (safe_rating.length < 1 ? 1 : safe_rating.length)
    return (
        <Container>

            <Progress multi max={100}>
                {safe_rating.map(item => (<Progress bar color={compute_color(item[valueKey])} value={"" + size} max={100}>{item[key]}</Progress>))}

            </Progress>
        </Container>

    )
}


export const render_line_chart = function (values, key, valueKey,name) {
    const safe_rating = values || []
    /**{safe_rating.map(item => (<Progress bar color={compute_color(item[valueKey])} value={"" + size} max={100}>{item[key]}</Progress>))}*/
    const size = 100 / (safe_rating.length < 1 ? 1 : safe_rating.length)
    const categories = []
    const data = []
    safe_rating.map(item => {
        categories.push(item[key] || '')
        data.push(item[valueKey] || '0')
    });

    const options = {
        stroke: {
            curve: 'smooth'
        },
        chart:{
            zoom: {
                enabled: false
              }
        },
        markers: {
            size: 0
        },
        xaxis: {
            categories: categories
        },
        "yaxis": [{
            "labels": {
                "formatter": function (val) {
                    return val.toFixed(2)
                }
            }
        }]
    };
    const series = [{
        name:name ||"",
        data: data
    }]
    return (
        <div className="line">
            <Chart options={options} series={series} type="line" width="55%" />
        </div>
    )
}
export const generateCompUID = function () {
    const d = new Date()
    return ["pulse", d.getTime(), d.getMilliseconds()].join('_')
}

export const convert_array_to_object = function (in_arr, key, value) {
    const json_obj = {}
    for (let index in in_arr) {
        let obj = in_arr[index] || {};
        json_obj[obj[key]] = obj[value]
    }
    return json_obj
}

export const goPreviousPage = function () {
    window.history.back(1)
}

export const camalize = function (str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toUpperCase() : match.toLowerCase();
    });
}

export const handleServerResponse = function (res, successMessage, errorMessage) {

    const isSuccess = get_value_from_object(res, "status", "") === "Success"
    if (isSuccess && successMessage != null) {
        showSuccessMessage(successMessage)
    } else if (!isSuccess) {
        showErrorMessage(errorMessage || get_value_from_object(res, "errorMessage", "System facing some issue please try again"))
    }
    return isSuccess
}


