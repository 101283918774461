import OauthService from './OauthService'

class AuthenticationService {

    is_loggedin(){
       return OauthService.isLoggedIn()
    }
    isLoginInprogress(){
        const authToken = OauthService.getQueryParam("code");
        return authToken != null && authToken.length>0;
    }
    performLogin(){
        OauthService.getAccessToken()
    }
    goToLogin(){
        OauthService.processLogin()
    }
    
    getUserName(){
        const user= OauthService.getUser();
        return user.username
    }

    getToken(){
        return OauthService.getToken()
    }

    getUserId(){
        
        return OauthService.getUser().userId
    }
    promptForLogin(model){
        
    }

    hasRole(role){
       const user= OauthService.getUser();
       return (user.roles || []).indexOf(role)>-1;
    }

    hasAnyRole(roles){
        const user= OauthService.getUser();
        let is_authorize = false
       for(let role in roles){
           
        if((user.roles || []).indexOf(roles[role])>-1){
            is_authorize = true
            break;
        }
       }
       console.log(is_authorize)
       return is_authorize;
    }
   
}

export default new AuthenticationService()