// OAUTH RELATED URLS
export const AUTHENTICATION_URL = "https://pulseoftheworkforce.com/services/oauth/login" //"https://pulseoftheworkforce.com/oauth2/authorize";
export const AUTHERIZATION_URL = "https://pulseoftheworkforce.com/services/oauth/accessToken"//"https://pulseoftheworkforce.com/oauth2/token";
export const USER_INFO_URL = "https://pulseoftheworkforce.com/oauth2/userinfo";
export const OAUTH_REDIRECT_URL = "https://pulseoftheworkforce.com/"

// ALL THE URLS WHICH ARE USED BY UI
export const BASE_URL = "https://pulseoftheworkforce.com/services";
export const POSITION_LIST_URL = "/Role/getAllRoles"
export const DASHBOARD_TASK_URL = "/Employee/dashboard/tasks"
export const DASHBOARD_REPORTEE_URL = "/Employee/dashboard/reportee"
export const CONTENT_CREATE_URL = "/ContentManagment/create"
export const CONTENT_MANAGE_LIST_URL = "/ContentManagment/getAllContents"
export const CONTENT_PUBLISH_URL = "/ContentManagment/publishContent"
export const CONTENT_UPDATE_URL = "/ContentManagment/updateContent"
export const CONTENT_DELETE_URL = "/ContentManagment/deleteContent"

// all roles are defined here
export const ROLE_MY_ASSESSMENT = "MY_ASSESSMENT";
export const ROLE_REVIEW_ASSESSMENT = "REVIEW_ASSESSMENT";
export const ROLE_MY_PROJECT = "MY_PROJECT";
export const ROLE_MY_GOALS = "MY_GOALS";
export const ROLE_MY_TRAININGS = "MY_TRAININGS";
export const ROLE_MY_SKILLS = "MY_SKILLS";

export const ROLE_MANAGE_EMPLOYEE = "MANAGE_EMPLOYEE";
export const ROLE_MANAGE_GOALS = "MANAGE_GOALS";
export const ROLE_MANAGE_TRAINING = "MANAGE_TRAINING";
export const ROLE_MANAGE_PROJECT = "MANAGE_PROJECT";
export const ROLE_MANAGE_POSITION = "MANAGE_POSITION";
export const ROLE_MANAGE_ASSESSMENT = "MANAGE_ASSESSMENT";
export const ROLE_MANAGE_CAPABILITY = "MANAGE_CAPABILITY";
export const ROLE_MANAGE_CONTENT = "MANAGE_CONTENT";


