import React from 'react';
import { Button, Modal, ModalHeader, ModalBody,  Row, Col, FormGroup, Input,Alert,Label, ModalFooter } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthenticationService from "../service/AuthenticationService"
const createSchema = Yup.object().shape({

    email: Yup.string().when("__resetpassword",{
        is:true,
        then:Yup.string().min(5, 'The minimum length is 5 for this field')
        .required('Please enter your registered email address')
    })
        
   

});
class LoginModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: this.props.show,
            email: '',
            __resetpassword:false
        }
    }
    __hide() {
        this.setState({ show: !this.state.show })
    }
    close(){
        this.setState({ show: false })
    }
    open(data){
        this.setState({
            ...this.state,
            ...data,
            __resetpassword:false,
            show:!this.state.show
        })

    }
    render() {
        return (
                <Modal isOpen={this.state.show} backdrop={false} className="login_modal"  >
                    <ModalHeader >Please login to access the application.</ModalHeader>
                    <ModalBody >
                        <Formik
                            enableReinitialize={true}
                            initialValues={this.state}
                            validationSchema={createSchema}
                            onSubmit={values => {
                               
                                this.props.save(values)
                            }}
                        >
                            {({ errors, touched, setFieldValue }) => (
                                <Form>
                                    <Row form>
                                        
                                       <Col md={12}>
                                       { this.state.__resetpassword === true && <FormGroup>
                                                
                                                <Label for="email">For forgot password please type email and click on 'Resend Password'</Label>
                                                <Input type="hidden" name="__resetpassword" value={this.state.__resetpassword} />
                                                <Input type="email" name="email" onChange={(evt) => { this.setState({ email: evt.target.value }); setFieldValue('email', evt.target.value) }} value={this.state.comment} />
                                                {errors.email && touched.email ? <Alert color="danger">{errors.email}</Alert> : null}
                                            </FormGroup>}
                                        </Col>  
                                    <Col md={12}>
                                        <Button color="primary" onClick={AuthenticationService.goToLogin.bind(this)}>Login</Button> {'  '}
                                        {this.state.__resetpassword === true && <Button color="primary" type="submit" >Resend Password</Button>} {'  '}
                                        {!this.state.__resetpassword && <Button color="primary" onClick={(evt)=>{ evt.preventDefault(); this.setState({__resetpassword:true})}} >Forgot Password</Button>} {'  '}
                                        
                                    </Col>
                                    </Row>

                                </Form>)}
                        </Formik>
                    </ModalBody>
                    <ModalFooter>
                        
                        <span style={{textAlign:"left"}} >If you dont have account please contact our sales team</span>
                    </ModalFooter>
                    
                </Modal>
         
        );
    }


}



export default LoginModal;